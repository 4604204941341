import React, { useEffect } from 'react'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import 'react-loading-skeleton/dist/skeleton.css'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes'
import PrivateRoutes from './shared/Routes/PrivateRoutes'
import PageNotFound from './containers/notfound/PageNotFound'
import Home from './containers/home/Home'
import Thankyou from './containers/thankyou/Thankyou'
import CompanyDetails from './containers/companydetails/CompanyDetails'
import DirectorDetails from './containers/directorDetails/DirectorDetails'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path={'/'} component={Home} />
          <Route exact path={'/thankyou'} component={Thankyou} />
          <Route exact path={'/company/:slug'} component={CompanyDetails} />
          <Route exact path={'/director/:slug'} component={DirectorDetails} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
