import React, { useState } from 'react'
import About from '../../components/about/About'
import CompanyInformation from '../../components/company-information/CompanyInformation'
import ContactDetails from '../../components/contactdetails/ContactDetails'
import Faq from '../../components/faq/Faq'
import FinalInformation from '../../components/financeInformation/FinalInformation'
import Header from '../../components/header/Header'
import NewRegister from '../../components/newregister/NewRegister'

function Home() {
  return (
    <div>
      <Header />
      <NewRegister />
    </div>
  )
}

export default Home
