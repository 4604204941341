import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { URI } from "../../domain/constant";
import { useParams } from "react-router-dom";

function Header() {
  let { slug } = useParams();
  const [name, setName] = useState(null);
  const [results, setResults] = useState(null);
  const [show, setshow] = useState(false);

  console.log("NAME", window.location.search);
  useEffect(() => {
    if (name && name.length > 2) {
      fetchData(name);
      setshow(true);
    }
  }, [name]);
  useEffect(() => {
    if (!name || name.length <= 2) {
      setResults(null);
      setshow(false);
    }
  }, [name]);
  const fetchData = async (name) => {
    const { data } = await axios.get(
      `${URI}/listings/all?term=company_name&value=${name}`
    );
    console.log("DATA", data);
    setResults(data);
  };

  useEffect(() => {
    setshow(false);
  }, [slug]);

  return (
    <section className="search">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto">
            <div className="name-searchh">
              <h3>Company / LLP Name Search</h3>

              <div className="company">
                <div>
                  <input
                    placeholder="Enter Company Name or LLP"
                    id="first_name"
                    maxLength={40}
                    name="first_name"
                    size={20}
                    // type="text"
                    autoComplete="false"
                    autoCorrect="false"
                    autofocus
                    required
                    type="search"
                    className="name-search"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  {show && results && (
                    <div className="search-history">
                      <ul>
                        {results.length > 0 ? (
                          <>
                            {results.map((item) => {
                              return (
                                <li>
                                  <Link to={`/company/${item.slug}`}>
                                    {item.name}
                                  </Link>
                                  <div>
                                    {item.directors &&
                                      item.directors.map((director) => {
                                        return (
                                          <Link>
                                            <span className="director-data">
                                              {" "}
                                              {director.name}{" "}
                                            </span>
                                          </Link>
                                        );
                                      })}
                                    <span
                                      className={
                                        item.status == "active"
                                          ? "badge bg-success"
                                          : "badge bg-warning"
                                      }
                                    >
                                      {item.status}
                                    </span>
                                  </div>
                                </li>
                              );
                            })}
                          </>
                        ) : (
                          <li>No Result Found</li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="last">
                  <button
                    className="nbutton"
                    type="button"
                    id="nextBtn"
                    onclick="nextPrev(1)"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
