import axios from 'axios'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useState } from 'react'
import { useEffect } from 'react'
import renderHTML from 'react-render-html'
import { URI } from '../../domain/constant'

function NewRegister() {
  const [loading, setloading] = useState(true)
  const [result, setResult] = useState(null)
  const fetchData = async () => {
    setloading(true)
    const { data } = await axios.get(`${URI}/listings/homepage`)
    console.log('DATA', data)
    setResult(data[0])
    setloading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div>
      {!loading ? (
        <section className="new-g ptb-50">
          <div className="container align-items-center">
            {result && (
              <div style={{ margin: '0 auto' }}>
                <div>{result && renderHTML(result)}</div>
              </div>
            )}
          </div>
        </section>
      ) : (
        <section className="new-g ptb-50">
          <div className="container align-items-center">
            <div className="row">
              <div className="col-4">
                <Skeleton height={250} />
              </div>
              <div className="col-4">
                <Skeleton height={250} />
              </div>
              <div className="col-4">
                <Skeleton height={250} />
              </div>
              <div className="col-3">
                <Skeleton height={250} />
              </div>
              <div className="col-3">
                <Skeleton height={250} />
              </div>
              <div className="col-3">
                <Skeleton height={250} />
              </div>
              <div className="col-3">
                <Skeleton height={250} />
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  )
}

export default NewRegister
