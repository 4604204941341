import React from 'react'
import About from '../../components/about/About'
import Skeleton from 'react-loading-skeleton'
import CompanyInformation from '../../components/company-information/CompanyInformation'
import ContactDetails from '../../components/contactdetails/ContactDetails'
import Faq from '../../components/faq/Faq'
import FinalInformation from '../../components/financeInformation/FinalInformation'
import Header from '../../components/header/Header'
import NewRegister from '../../components/newregister/NewRegister'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { URI } from '../../domain/constant'
import renderHTML from 'react-render-html'

function CompanyDetails({ match }) {
  const [loading, setloading] = useState(true)
  const [company, setCompany] = useState(null)

  const fetchData = async (id) => {
    setloading(true)
    const { data } = await axios.get(`${URI}/listings/${id}`)
    console.log('DATA', data)

    setCompany(data)
    setloading(false)
  }
  useEffect(() => {
    if (match.params.slug) {
      fetchData(match.params.slug)
    }
  }, [match.params.slug])
  const [company_data, setCompany_data] = useState(null)
  // useEffect(() => {
  //   if (company && company.data) {
  //     setCompany_data(JSON.parse(company.data));
  //   }
  // }, [company]);
  // console.log("company Data", company_data);
  console.log('COMPANY', company)
  return (
    <>
      <Header company={company} />
      <div className="container">
        {!loading ? (
          <div className="row">
            <div className="col-9">
              <div className="description">
                <h3> Company Details </h3> <hr />
                {company &&
                  company.description &&
                  renderHTML(company.description)}
              </div>

              <hr />

              <div className="description">
                {company &&
                  company.directorDetails &&
                  company.directorDetails.map((item) => {
                    return (
                      <div>
                        {renderHTML(item)} <hr />{' '}
                      </div>
                    )
                  })}
              </div>

              <div className="faq-main">
                {company && company.faqs && renderHTML(company.faqs)}
              </div>
            </div>
            <div className="col-md-3">
              <div className="states">
                {company &&
                  company.stats &&
                  company.stats.map((item) => {
                    return (
                      <div>
                        {renderHTML(item)} <hr />{' '}
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        ) : (
          <section className="new-g ptb-50">
            <div className="container align-items-center">
              <div className="row">
                <div className="col-9">
                  <Skeleton height={550} />
                </div>
                <div className="col-3">
                  <Skeleton height={450} />
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      {/* <About company={company} company_data={company_data} />

      <CompanyInformation company={company} company_data={company_data} />
      <FinalInformation company={company} company_data={company_data} />
      <ContactDetails company={company} company_data={company_data} />
      <Faq company={company} company_data={company_data} /> */}
      {/* <NewRegister /> */}
    </>
  )
}

export default CompanyDetails
