import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <>
      <section className>
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="nofound-iamge">
                <img src="/assets/images/404.png" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="thankyou-content text-center">
                <div className="contact-us text-center">
                  <a className="btn btn-contact-us" href="/">
                    Return Homepage
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound)
