import React from "react";
import About from "../../components/about/About";
import CompanyInformation from "../../components/company-information/CompanyInformation";
import ContactDetails from "../../components/contactdetails/ContactDetails";
import Faq from "../../components/faq/Faq";
import FinalInformation from "../../components/financeInformation/FinalInformation";
import Header from "../../components/header/Header";
import NewRegister from "../../components/newregister/NewRegister";
import axios from "axios";
import { useEffect, useState } from "react";
import { URI } from "../../domain/constant";
import renderHTML from "react-render-html";

function DirectorDetails({ match }) {
  const [company, setCompany] = useState(null);

  const fetchData = async (id) => {
    const { data } = await axios.get(`${URI}/listings/directors/${id}`);
    console.log("DATA", data);

    setCompany(data);
  };
  useEffect(() => {
    if (match.params.slug) {
      fetchData(match.params.slug);
    }
  }, [match.params.slug]);
  const [company_data, setCompany_data] = useState(null);
  // useEffect(() => {
  //   if (company && company.data) {
  //     setCompany_data(JSON.parse(company.data));
  //   }
  // }, [company]);
  // console.log("company Data", company_data);
  console.log("COMPANY", company);
  return (
    <>
      <Header company={company} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            {" "}
            <div className="description">
              <h3> Director Details </h3> <hr />
              {company &&
                company.description &&
                renderHTML(company.description)}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="row">
          <div className="col-12">
            {" "}
            <div className="description">
              {company &&
                company.directorDetails &&
                company.directorDetails.map((item) => {
                  // let newItem = item.replaceAll(
                  //   "https://www.quickcompany.in/",
                  //   "/"
                  // );
                  // let antoherItem = newItem.replaceAll(
                  //   `src="/assets/supericons/black-director-98b665bdc4990b19153be381d3e2fa203dd437b89a32926724782ccdf8d3b0e1.svg"`,
                  //   `src="/user.png"`
                  // );
                  return (
                    <div>
                      {renderHTML(item)} <hr />{" "}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {/* <About company={company} company_data={company_data} />

      <CompanyInformation company={company} company_data={company_data} />
      <FinalInformation company={company} company_data={company_data} />
      <ContactDetails company={company} company_data={company_data} />
      <Faq company={company} company_data={company_data} /> */}
      {/* <NewRegister /> */}
    </>
  );
}

export default DirectorDetails;
