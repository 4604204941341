import React from 'react'

function FinalInformation({ company, company_data }) {
  return (
    <section className="finance">
      {company && company_data && (
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="company-info-heading">
                <h3>FINANCIAL INFORMATION</h3>
                <div className="row rawData">
                  <div className="col-sm-4">Obligation of Contribution </div>
                  <div className="col-sm-8">
                    Rs. {company_data['Obligation of Contribution']}
                  </div>
                </div>
                <div className="row rawData">
                  <div className="col-sm-4">Latest Annual Report</div>
                  <div className="col-sm-8">N/A</div>
                </div>
                <div className="row rawData">
                  <div className="col-sm-4">Latest Balance Sheet</div>
                  <div className="col-sm-8">N/A</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default FinalInformation
